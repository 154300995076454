import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import { rhythm } from "../utils/typography"
import SEO from "../components/seo"

const PostHeader = styled.h3`
  font-family: merriweather;
  margin-bottom: ${rhythm(1 / 4)};
  & > span {
    color: #999;
  }
`
const VideoContainer = styled.div`
  & > * {
    width: 560px;
    height: 315px;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
`

export default ({ data }) => (
  <Layout at={"blog"} maxWidth={800}>
    <SEO title="Blog" />
    <h1>Blog</h1>
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <div key={node.id}>
        <Link to={node.fields.slug}>
          <PostHeader>
            {node.frontmatter.title}
            <span> - {node.frontmatter.date}</span>
          </PostHeader>
          {node.frontmatter.video ? (
            <VideoContainer
              dangerouslySetInnerHTML={{ __html: node.frontmatter.video }}
            />
          ) : (
            <p>{node.excerpt}</p>
          )}
        </Link>
      </div>
    ))}
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            video
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
